import React from 'react'
import { FAQCont, HeadingText } from '../../styles'
import Collapse, { Panel } from 'rc-collapse'
import 'rc-collapse/assets/index.css'
import { useStaticQuery, graphql } from 'gatsby'
import { isMobile } from 'react-device-detect'

const FAQ = () => {
  const faqs = useStaticQuery(graphql`
    query FaqQuery {
      allContentfulFaq {
        edges {
          node {
            question
            body {
              body
            }
          }
        }
      }
    }
  `)

  const renderFaq = () => {
    return faqs.allContentfulFaq.edges.map((faq, index) => {
      return (
        <Panel
          header={faq.node.question}
          headerClass="my-header-class mouse-link"
          key={index}
        >
          {faq.node.body.body}
        </Panel>
      )
    })
  }

  function expandIcon({ isActive }) {
    return (
      <>
        {!isMobile && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="plus"
            width="20"
            height="20"
            viewBox="0 0 160 160"
            fill="currentColor"
            style={{
              margin: '0 10px 0 0',
              transition: 'transform .3s',
              transform: `rotate(${isActive ? 135 : 0}deg)`,
              transformOrigin: 'center',
            }}
          >
            <rect className="vertical-line" x="70" width="20" height="160" />
            <rect className="horizontal-line" y="70" width="160" height="20" />
          </svg>
        )}
        {isMobile && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="plus"
            width="10"
            height="10"
            viewBox="0 0 160 160"
            fill="currentColor"
            style={{
              margin: '0 10px 0 0',
              transition: 'transform .3s',
              transform: `rotate(${isActive ? 135 : 0}deg)`,
              transformOrigin: 'center',
            }}
          >
            <rect className="vertical-line" x="70" width="20" height="160" />
            <rect className="horizontal-line" y="70" width="160" height="20" />
          </svg>
        )}
      </>
    )
  }

  return (
    <FAQCont>
      <HeadingText className="black bottomSpace  smaller">
        Frequently Asked Questions
      </HeadingText>
      <Collapse accordion={true} expandIcon={expandIcon}>
        {renderFaq()}
      </Collapse>
    </FAQCont>
  )
}

export default FAQ
