import React from 'react'

import SEO from '~/components/seo'
import FAQ from '../components/Faq'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'

const FAQPage = () => (
  <>
    <SEO title="FAQ" keywords={[`gatsby`, `application`, `react`]} />
    <Navigation />
    <FAQ />
    <Footer />
  </>
)

export default FAQPage
